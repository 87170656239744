// import lazyLoading from '../lazyLoading';

export default {
  name: 'permissionpackagelist',
  caption: 'Permission Package List',
  wdm16_id: '',
  icon: 'icon-list',
  path: '/permissionpackagelist',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: ['wisdom'],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
