// import lazyLoading from '../lazyLoading';

export default {
  name: 'documentlist',
  caption: 'Document List',
  wdm16_id: '',
  icon: 'icon-list',
  path: '/documentlist',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: ['documentation-edit'],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
