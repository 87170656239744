// import lazyLoading from '../lazyLoading';

export default {
  name: 'subscription',
  caption: 'Subscription (wisdom)',
  wdm16_id: '',
  icon: 'icon-list',
  path: '/subscription',
  // component: lazyLoading('patient/index'),
  required: [],
  permissions: ['wisdom'],
  meta: {
    required: [],
    icon: 'fa-bars',
    expanded: false
  }
};
